var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ContentSection',[_c('PDataTable',{ref:"dt",staticClass:"p-datatable-sm",attrs:{"value":_vm.tempValuations,"lazy":true,"paginator":true,"rows":50,"paginatorTemplate":"CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown","rowsPerPageOptions":[10, 20, 50, 100, 200, 500, 1000, 2000, 5000],"currentPageReportTemplate":"Showing {first} to {last} of {totalRecords}","showGridlines":"","filters":_vm.filters,"dataKey":"TempValuationId","totalRecords":_vm.totalRecords,"loading":_vm.loading,"filterDisplay":"menu","responsiveLayout":"scroll"},on:{"update:filters":function($event){_vm.filters=$event},"page":function($event){return _vm.onPage($event)},"filter":function($event){return _vm.onFilter($event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('span',[_vm._v("Orders")]),_c('div',{staticClass:"table-header__actions"},[(_vm.$store.state.identity.IsAdmin)?_c('PButton',{attrs:{"icon":"pi pi-external-link","label":"Export CSV"},on:{"click":function($event){return _vm.exportCSV($event)}}}):_vm._e(),_c('PButton',{staticClass:"p-button-outlined",attrs:{"type":"button","icon":"pi pi-refresh","label":"Refresh"},on:{"click":function($event){return _vm.loadLazyData()}}}),_c('PButton',{staticClass:"p-button-outlined",attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Clear"},on:{"click":function($event){return _vm.onClearFilters()}}})],1)])]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" No pre valuations found. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Loading pre valuations data. Please wait. ")]},proxy:true}])},[_c('PColumn',{attrs:{"header":"Status","field":"Status","showFilterMatchModes":false,"styles":{ 'vertical-align': 'middle' }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('PMultiSelect',{staticClass:"p-column-filter",attrs:{"options":_vm.statuses,"placeholder":"Any"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(slotProps.option))])]}}],null,true),model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"header":"Type","field":"Type","showFilterMatchModes":false,"styles":{ 'vertical-align': 'middle' }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('PMultiSelect',{staticClass:"p-column-filter",attrs:{"options":_vm.types,"placeholder":"Any"},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(slotProps.option))])]}}],null,true),model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"header":"Address","field":"Address","showFilterMatchModes":false,"styles":{ 'vertical-align': 'middle', 'max-width': '220px' }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('PInputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by Address"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"header":"Created On","field":"CreateDate.Value","dataType":"date","styles":{ 'vertical-align': 'middle' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.CreateDate))+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('PCalendar',{attrs:{"dateFormat":"dd/mm/yy","placeholder":"dd/mm/yyyy"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"header":"Name","field":"Name","showFilterMatchModes":false,"styles":{ 'vertical-align': 'middle' }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('PInputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by Name"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"header":"Valuation Date","field":"RetrospectiveDate.Value","dataType":"date","styles":{ 'vertical-align': 'middle' }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.RetrospectiveDate))+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('PCalendar',{attrs:{"dateFormat":"dd/mm/yy","placeholder":"dd/mm/yyyy"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('PColumn',{attrs:{"exportable":false,"styles":{ 'vertical-align': 'middle', 'width': '0px' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.Status == 'In Progress' || (_vm.$store.state.identity.User.UserId ==  'ec2a7ee0-5084-4ea0-8183-d5488c03283b' ||  _vm.$store.state.identity.User.UserId ==  'ba7d5488-c5b0-4f67-83b9-6e6881ad3bd4'))?_c('PButton',{attrs:{"icon":"pi pi-file-edit"},on:{"click":function($event){return _vm.editTempValuation(slotProps.data)}}}):_vm._e()]}}])}),_c('PColumn',{attrs:{"exportable":false,"styles":{ 'vertical-align': 'middle', 'width': '0px' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.Status == 'Completed')?_c('PButton',{attrs:{"icon":"pi pi-copy"},on:{"click":function($event){return _vm.askCopyTempValuation(slotProps.data)}}}):_vm._e()]}}])})],1),_c('ConfirmDialog',{staticClass:"confirm-dialog"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";
import ValuationModel from "../../../models/api/valuations/ValuationModel"
import SetValuationModel from "../../../models/api/valuations/SetValuationModel";
import GetValuationModel from "../../../models/api/valuations/GetValuationModel";
import GetValuationBlockModel from "../../../models/api/valuations/GetValuationBlockModel";
import GetAttachedFileModel from "../../../models/api/files/GetAttachedFileModel";
import { GetReportModel } from "../../../models/api/reports";
import GetPracticeModel from "../../../models/api/practices/GetPracticeModel";
import GetValuerProfileModel from "../../../models/api/valuations/GetValuerProfileModel";
import { ValuationStatus } from "../../../models/api/valuations/ValuationStatus";
import TempValuationModel from "../../../models/api/valuations/TempValuationModel";
import { UserModel } from "../../../models/api/users";

export default class ValuationAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient)
  }

  GetValuations(statuses: ValuationStatus[] = []): Promise<ValuationModel[]> {
    return this.GET({
      Path: `/api/valuations`,
      QueryParams: {
        statuses: statuses.length ? statuses.join(',') : [ValuationStatus.Pending, ValuationStatus.InProgress, ValuationStatus.Completed].join(',')
      }
    })
  }

  GetValuation(valuationId: string): Promise<GetValuationModel> {
    return this.GET({
      Path: `/api/valuations/${valuationId}`
    })
  }

  SetValuationPractice (valuationId: string, practiceId: string): Promise<GetPracticeModel> {
    return this.POST({
      Path: `/api/valuations/${valuationId}/practice`,
      Body: {
        PracticeId: practiceId
      }
    })
  }

  SetValuationValuer (valuationId: string, valuerId: string): Promise<GetValuerProfileModel> {
    return this.POST({
      Path: `/api/valuations/${valuationId}/valuer`,
      Body: {
        ValuerId: valuerId
      }
    })
  }

  async SetValuationImage (valuationId: string, fileId: string, slot: number): Promise<void> {
    await this.PUT({
      Path: `/api/valuations/${valuationId}/images`,
      Body: {
        FileId: fileId,
        Slot: slot
      }
    })
  }

  SaveValuation(model: SetValuationModel): Promise<SetValuationModel> {
    return this.PATCH({
      Path: `/api/valuations/${model.ValuationId}`,
      Body: model
    })
  }

  async SetStatus (valuationId: string, status: ValuationStatus): Promise<void> {
    this.POST({
      Path: `/api/valuations/${valuationId}/status`,
      Body: {
        Status: status
      }
    })
  }

  CreateValuationBlock(model: GetValuationBlockModel): Promise<GetValuationBlockModel> {
    return this.PATCH({
      Path: '/api/valuations/block',
      Body: model
    })
  }

  GetFiles(id: string): Promise<GetAttachedFileModel[]> {
    return this.GET({
      Path: `/api/valuations/${id}/files`,
    })
  }

  AttachFile(valuationId: string, fileId: string, slot: string): Promise<GetAttachedFileModel> {
    return this.POST({
      Path: `/api/valuations/${valuationId}/files/attach`,
      Body: {
        FileId: fileId,
        Slot: slot
      }
    })
  }

  GetReports(id: string): Promise<GetReportModel[]> {
    return this.GET({
      Path: `/api/valuations/${id}/reports`
    })
  }

  // eslint-disable-next-line
  public PostTempValuationsFilter(filter: any): Promise<{TempValuations: TempValuationModel[]; TotalCount: number}> {
    return this.POST({
      Path: '/api/valuations/filter',
      Body: filter
    })
  }

  public GetOwner(valuationId: string): Promise<UserModel> {
    return this.POST({
      Path: `/api/valuations/owner`,
      Body: valuationId
    })
  }

  public GetRepresentative(valuationId: string): Promise<GetPracticeModel> {
    return this.POST({
      Path: `/api/valuations/representative`,
      Body: valuationId
    })
  }

  public GetTempValuation(valuationId: string): Promise<string> {
    return this.POST({
      Path: `/api/valuations/temp_valuation`,
      Body: valuationId
    })
  }

  public CreateValuationUser(valuationId: string, firstName: string, lastName: string, email: string, phone: string, businessName: string, password: string): Promise<string> {
    return this.POST({
      Path: `/api/valuations/create_valuation_user`,
      Body: {
        ValuationId: valuationId,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        BusinessName: businessName,
        Password: password,
      }
    })
  }

  public CreateValuationPractice(valuationId: string, firstName: string, lastName: string, email: string, phone: string, businessName: string, websiteUrl: string, roleName: string, password: string): Promise<string> {
    return this.POST({
      Path: `/api/valuations/create_valuation_practice`,
      Body: {
        ValuationId: valuationId,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        BusinessName: businessName,
        WebsiteUrl: websiteUrl,
        RoleName: roleName,
        Password: password,
      }
    })
  }

  
  public CreateTempValuation(ownerId: string, practiceId: string, valuationId: string): Promise<string> {
    return this.POST({
      Path: `/api/valuations/create_temp_valuation`,
      Body: {
        OwnerId: ownerId,
        PracticeId: practiceId,
        ValuationId: valuationId
      }
    })
  }

}
